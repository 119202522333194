module.exports = () => {
    jQuery(document).ready(function() {
        // MOBILE MENU EXPANDING/COLLAPSING 
        let hamburger = document.getElementById('hamburger');
        let mobileMenu = document.getElementById('mobile-menu');
        
        
        hamburger.onclick = function() {
            // if we are closing the menu
            if (this.classList.contains('is-active')) {
                this.classList.remove('is-active');
                mobileMenu.classList.remove('mobile-menu-open');
                document.body.style.overflow = 'visible';
                document.body.style.height = 'auto';
            } 
            // otherwise we are opening the menu
            else {
                this.classList.add('is-active');
                mobileMenu.classList.add('mobile-menu-open');
                document.body.style.overflow = 'hidden';
                document.body.style.height = '100vh';
            }
        }

        // close mobile menu if clicking off menu
        document.addEventListener('click', function(e) { 
            // if (jQuery(e.target).parents(hamburger).length === 0 && jQuery(e.target).parents(mobileMenu).length === 0 && hamburger.classList.contains('is-active')) {
            if (!jQuery(hamburger).is(e.target) && jQuery(hamburger).has(e.target).length === 0 &&
            !jQuery(mobileMenu).is(e.target) && jQuery(mobileMenu).has(e.target).length === 0 &&
            hamburger.classList.contains('is-active')) {
                hamburger.classList.remove('is-active');
                mobileMenu.classList.remove('mobile-menu-open');
                document.body.style.overflow = 'visible';
                document.body.style.height = 'auto';
            }
        });
    
    
        // Expand sub menus on the mobile menu
        let buttons = document.querySelectorAll('.mobile-menu .menu-item-has-children b');
        buttons.forEach((button) => {
            button.addEventListener('click', function(e) {
                e.preventDefault();
                let button = this;
                let parent = this.parentNode;
                let subMenu = this.parentNode.nextElementSibling;
    
                if (parent.classList.contains('open')) {
                    parent.classList.remove('open');
                    subMenu.style.height = '0px';
    
                    button.style.transform = 'translateY(-50%)';
                } else {
                    parent.classList.add('open');
                    subMenu.style.height = subMenu.scrollHeight + 'px';
    
                    button.style.transform = 'rotateX(180deg) translateY(50%)';
                }
            })
        });




        // for mega menu
        // add class to first item
        let firstLevelMenuItems = document.querySelectorAll('#menu-mega-menu > li > a:not([href="#"])');
        firstLevelMenuItems[0].classList.add('current');

        // functionality to change class name on hover of first level menu items
        for (let i=0; i<firstLevelMenuItems.length; i++) {
            let item = firstLevelMenuItems[i];
            item.addEventListener('mouseover', function() {
                for (let j=0; j<firstLevelMenuItems.length; j++) {
                    firstLevelMenuItems[j].classList.remove('current');
                }
                this.classList.add('current');
            })
        }

    })
}