
/* --- SLIDER --- */
module.exports = () => {
	jQuery(document).ready(function () {

		jQuery('.slider').slick({
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 3,
			arrows: false,
			centerPadding: '0px',
			autoplay: false,
			autoplaySpeed: 2000,
			mobileFirst: true,
			swipeToSlide: true,
			responsive: [
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 540,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			]
		});
		jQuery(window).resize(function(){
			jQuery('.slider').slick('resize'); // recalc on window resize
		});


		jQuery('.central_slick').slick({
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			centerPadding: '0px',
			centerMode: true,
			autoplay: false,
			autoplaySpeed: 2000,
			mobileFirst: true,
			swipeToSlide: true,
			variableHeight: false,
		});
		jQuery(window).resize(function(){
			jQuery('.slider').slick('resize'); // recalc on window resize
		});



		jQuery('.off_center_slick').slick({
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			centerPadding: '0px',
			centerMode: false,
			autoplay: false,
			autoplaySpeed: 2000,
			mobileFirst: true,
			swipeToSlide: true,
			variableHeight: false,
			responsive: [
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				}
			]
		});
		jQuery(window).resize(function(){
			jQuery('.off_center_slick').slick('resize'); // recalc on window resize
		});





		jQuery('.hero_slider').slick({
			dots: true,
			infinite: false,
			speed: 700,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			centerPadding: '0px',
			autoplay: true,
			autoplaySpeed: 2500,
			mobileFirst: true,
			swipeToSlide: true,
		});
		jQuery(window).resize(function(){
			jQuery('.hero_slider').slick('resize'); // recalc on window resize
		});




		jQuery('.reviews_slider').slick({
			dots: false,
			infinite: true,
			speed: 800,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			centerPadding: '0px',
			autoplay: true,
			autoplaySpeed: 2000,
			mobileFirst: true,
			swipeToSlide: true,
		});
		jQuery(window).resize(function(){
			jQuery('.reviews_slider').slick('resize'); // recalc on window resize
		});



		jQuery('.clients-slider').slick({
			speed: 2000,
			autoplay: true,
			autoplaySpeed: 0,
			cssEase: 'linear',
			slidesToShow: 1,
			slidesToScroll: 1,
			variableWidth: true,
			arrows: false
		}); 
		jQuery(window).resize(function(){
			jQuery('.clients-slider').slick('resize'); // recalc on window resize
		});




		jQuery('.accreds_global_slider').slick({
			speed: 2000,
			autoplay: true,
			autoplaySpeed: 0,
			cssEase: 'linear',
			slidesToShow: 1,
			slidesToScroll: 1,
			variableWidth: true,
			mobileFirst: true,
			arrows: false,
			responsive: [
				{
					breakpoint: 1100,
					settings: "unslick"
				}
			]
		}); 
		jQuery(window).resize(function(){
			jQuery('.accreds_global_slider').slick('resize'); // recalc on window resize
		});


		jQuery('.header-accreditations').slick({
			speed: 2000,
			autoplay: true,
			autoplaySpeed: 0,
			cssEase: 'linear',
			slidesToShow: 1,
			slidesToScroll: 1,
			variableWidth: true,
			mobileFirst: true,
			arrows: false,
			responsive: [
				{
					breakpoint: 1100,
					settings: "unslick"
				}
			]
		}); 
		jQuery(window).resize(function(){
			jQuery('.header-accreditations').slick('resize'); // recalc on window resize
		});

		jQuery('.footer-accreditations').slick({
			speed: 2000,
			autoplay: true,
			autoplaySpeed: 0,
			cssEase: 'linear',
			slidesToShow: 1,
			slidesToScroll: 1,
			variableWidth: true,
			mobileFirst: true,
			arrows: false,
			responsive: [
				{
					breakpoint: 1100,
					settings: "unslick"
				}
			]
		}); 
		jQuery(window).resize(function(){
			jQuery('.footer-accreditations').slick('resize'); // recalc on window resize
		});

		


	});
}